import { type SliderCtaWithBadgesSlideMapped } from "@services/wordpress/wordpress.types";
import { useEffect } from "preact/hooks";
import Swiper from "swiper";
import { Slide } from "./Slide";
import "swiper/css";
import "./index.scss";

export const SwiperCarousel = ({ slides }: {
  slides: SliderCtaWithBadgesSlideMapped[];
}) => {
  useEffect(() => {
    const swiper = new Swiper(".slider-cta-with-badges-swiper", {
      breakpoints: {
        870: {
          spaceBetween: 24,
        }
      },
      freeMode: true,
      loop: false,
      slidesOffsetAfter: 16,
      slidesOffsetBefore: 16,
      slidesPerView: "auto",
      spaceBetween: 16,
    });

    return () => swiper.destroy();
  }, []);

  return (
    <div className="slider-cta-with-badges-swiper swiper">
      <ul className="swiper-wrapper">
        {slides.map((slide, index) => (
          <Slide key={`slider-cta-with-badges-slide-${index}`} slide={slide} />
        ))}
      </ul>
    </div>
  );
};
