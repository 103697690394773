import { type SliderCtaWithBadgesSlideMapped } from "@services/wordpress/wordpress.types";
import { useEffect, useState } from "preact/hooks";
import "./index.scss";

export const Slide = ({ slide }: {
  slide: SliderCtaWithBadgesSlideMapped;
}) => {
  const [iconComponents, setIconComponents] = useState<Record<string, any>>({});

  useEffect(() => {
    const loadIcons = async () => {
      const icons: Record<string, any> = {};

      const importIcon = async (iconName: string) => {
        if (!icons[iconName] && iconName !== "none") {
          try {
            const module = await import(`../../../../icons/${iconName}-icon.tsx`);
            icons[iconName] = module.default;
          } catch (error) {
            icons[iconName] = null;
          }
        }
      };

      const allIcons = [
        ...slide.badges.map((badge) => badge.icon),
        slide.icon_for_badge_at_the_top,
      ];

      await Promise.all(allIcons.map(importIcon));

      setIconComponents(icons);
    };

    loadIcons();
  }, [slide]);

  const renderIcon = (iconName: string) => {
    const IconComponent = iconComponents[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  return (
    <li className="slider-cta-with-badges-slide swiper-slide">
      {slide.background_image && (
        <img
          alt={slide.background_image.alt}
          className="slider-cta-with-badges-slide__background-image"
          height={slide.background_image.height}
          src={slide.background_image.src}
          width={slide.background_image.width}
        />
      )}
      <div className="slider-cta-with-badges-slide__content">
        <span className="slider-cta-with-badges-slide__top-badge">
          {renderIcon(slide.icon_for_badge_at_the_top)}
          {slide.image_for_badge_at_the_top && (
            <img
              alt={slide.image_for_badge_at_the_top.alt}
              className="slider-cta-with-badges-slide__top-badge-image"
              height={slide.image_for_badge_at_the_top.height}
              src={slide.image_for_badge_at_the_top.src}
              width={slide.image_for_badge_at_the_top.width}
            />
          )}
        </span>
        <div className="slider-cta-with-badges-slide__badges-description">
          <div className="slider-cta-with-badges-slide__badges">
            {slide.badges.map(badge => (
              <div className="slider-cta-with-badges-slide__badge">
                {renderIcon(badge.icon)} <span>{badge.badge_text}</span>
              </div>
            ))}
          </div>
          <div className="slider-cta-with-badges-slide__description">
            <h5 className="slider-cta-with-badges-slide__heading">
              {slide.heading}
            </h5>
            <p>
              {slide.content}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
