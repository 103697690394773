import { type FC } from "preact/compat";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { useEffect } from "preact/hooks";
import "swiper/css";
import "swiper/css/navigation";
import "./index.scss";
import Icons from "@components/icons";
import { BlogPostCard } from "@components/BlogPostCard";
import { type PostMapped } from "@services/wordpress/wordpress.types";

interface SwiperCarouselProps {
  badge?: string,
  posts: PostMapped[];
}

export const SwiperCarousel: FC<SwiperCarouselProps> = ({ badge, posts }) => {
  useEffect(() => {
    const swiper = new Swiper(".featured-posts-carousel--swiper", {
      freeMode: true,
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 32
      ,
      modules: [Navigation],
      navigation: {
        nextEl: ".featured-posts-carousel__navigation-button--next",
        prevEl: ".featured-posts-carousel__navigation-button--prev",
      },
      slidesOffsetAfter: 16,
      slidesOffsetBefore: 16,
    });

    return () => swiper.destroy();
  }, []);

  return (
    <div className="featured-posts-carousel featured-posts-carousel--swiper swiper">
      <div className="featured-posts-carousel__container swiper-wrapper">
        {posts.map((post: PostMapped, index: number) => (
          <BlogPostCard
            excerpt={post?.excerpt?.rendered}
            newStyles={true}
            title={post?.title?.rendered ?? ""}
            featuredMedia={post.featuredMedia}
            slug={post.slug}
            categoryName={badge}
            author={post.acf.author}
            authorImage={post.authorImage}
          />
        ))}
      </div>
      <div className="featured-posts-carousel__navigation">
        <button className="featured-posts-carousel__navigation-button featured-posts-carousel__navigation-button--prev">
          <Icons.IconLeft />
        </button>
        <button className="featured-posts-carousel__navigation-button featured-posts-carousel__navigation-button--next">
          <Icons.IconRight />
        </button>
      </div>
    </div>
  );
};
