import { useEffect } from "preact/hooks";
import "./index.scss";

export const CollapsibleContent = ({ content }: {
  content: string,
}) => {
  useEffect(() => {
    const toggle = document.querySelector(
      "[data-comp-collapsible-content-toggle]"
    ) as HTMLElement | null;

    if (!toggle) return;

    const handleClick = () => {
      const isExpanded = toggle.classList.toggle("media-cta-collapsible__toggle--expanded");
      toggle.innerText = isExpanded ? "Less" : "More";
    };

    toggle.addEventListener("click", handleClick);

    return () => toggle.removeEventListener("click", handleClick);
  }, []);

  return (
    <div className="media-cta-collapsible">
      <button
        type="button"
        className="media-cta-collapsible__toggle"
        data-comp-collapsible-content-toggle
      >
        More
      </button>
      <div
        className="media-cta-collapsible__content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
