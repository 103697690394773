import { useEffect } from "preact/hooks";
import { type AccordionItemMappedProps } from "@services/wordpress/wordpress.types";
import { AccordionItem } from "@components/Accordians/AccordionItem";
import "./index.scss";

export const Accordion = ({
  accordions
}: {
  accordions: AccordionItemMappedProps[],
}) => {
  const imageHiddenClass = 'accordion-cta-with-media-section__image-container--hidden';
  useEffect(() => {
    const accordion = document.querySelector('[data-comp-accordion-media-cta]');
    const accordionButtons = accordion?.querySelectorAll('[data-comp-accordion-item-button]');
    const accordionImages = accordion?.querySelectorAll('[data-comp-accordion-media-cta-image]');

    accordionButtons?.forEach((button, index: number) => {
      button.addEventListener('click', () => {
        accordionImages?.forEach((image) => image.classList.add(imageHiddenClass));
        if (accordionImages) {
          accordionImages[index]?.classList.remove(imageHiddenClass);
        }
      });
    });
  }, []);

  return (
    <div className="accordion-cta-with-media-section" data-comp-accordion-media-cta>
      <ul className="accordion-cta-with-media-section__images">
        {accordions?.map((accordion, index) => (
          accordion.desktop_image && (
            <li
              className={`accordion-cta-with-media-section__image-container ${index !== 0 ? 'accordion-cta-with-media-section__image-container--hidden' : ''}`}
              key={`accordion-cta-with-media-section-image-${index}`}
              data-comp-accordion-media-cta-image
            >
              <img
                alt={accordion.desktop_image.alt_text || 'Accordion image'}
                className="accordion-cta-with-media-section__image"
                height={accordion.desktop_image.media_details?.height || undefined}
                src={accordion.desktop_image.source_url}
                width={accordion.desktop_image.media_details?.width || undefined}
              />
            </li>
          )
        ))}
      </ul>

      <ul className="accordion-cta-with-media-section__accordion-list">
        {accordions.map((accordion: AccordionItemMappedProps, index: number) => (
          <li
            className="accordion-cta-with-media-section__accordion-list-item"
            key={`accordion-cta-with-media-section-accordion-${index}`}
          >
            <AccordionItem
              heading={accordion.heading}
              includeNumberIcons={true}
              isOpen={index === 0}
              modifierClass="accordion-item--cta-with-media"
              newStyles={true}
              number={index + 1}
            >
              <div
                class='accordion-cta-with-media-section__accordion-list-item-content'
                dangerouslySetInnerHTML={{ __html: accordion.copy }}
              />
              {accordion.call_to_action && (
                <a
                  className="accordion-cta-with-media-section__accordion-cta"
                  href={accordion.call_to_action.url}
                  target={accordion.call_to_action.target}
                >
                  {accordion.call_to_action.title}
                </a>
              )}
              {accordion.mobile_image && (
                <div className="accordion-cta-with-media-section__accordion-list-item-image-container">
                  <img
                    alt={accordion.mobile_image.alt_text}
                    className="accordion-cta-with-media-section__accordion-list-item-image"
                    height={accordion.mobile_image.media_details.height}
                    src={accordion.mobile_image.source_url}
                    width={accordion.mobile_image.media_details.width}
                  />
                </div>
              )}
            </AccordionItem>
          </li>
        ))}
      </ul>
    </div>
  );
};
